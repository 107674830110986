.about-title, .about-hobbies, .sub-title2 {
    position: relative;
    text-align: left;
    margin: 0px 20px 0px 20px;
    color: white;
}

.sub-title2 {
    margin-top: 20px;
    margin-bottom: 20px;
}

.about-who, .about-tech, .hobbies{
    margin: 35px 0px 35px 0px;
    text-align: center;
}

.desc {
    margin: auto;
    position: relative;
    width: 80%;
    max-width: 1000px;
}

.about-tech-title {
    text-align: left;
    margin-left: 20px;
    margin-right: 20px;
}

.tech-icons {
    position: relative;
    display: flex;
    flex-direction: column;
}

.icon {
    width: 15%;
}

.icon h4 {
    font-size: 1.5vw;
}

.row1, .row2, .row3{
    display: flex;
    width: 85%;
    margin: 20px auto 20px auto;
    justify-content:space-between;
}

.animate-before-tech .ic0 {
    opacity: 0;
    transform: translateX(-100%);
}

.animate-before-tech .ic1 {
    opacity: 0;
    transform: translateX(-200%);
}

.animate-before-tech .ic2 {
    opacity: 0;
    transform: translateX(-300%);
}

.animate-before-tech .ic3 {
    opacity: 0;
    transform: translateX(-400%);
}

.animate-after-tech .ic0, .animate-after-tech .ic1, .animate-after-tech .ic2, .animate-after-tech .ic3 {
    opacity: 1;
    transform: translateX(0px);
    transition: all 1.5s;
}

.animate-before-tech-title {
    opacity: 0;
    transform : translateX(-250%);
}

.animate-after-tech-title {
    opacity: 1;
    transform : translateX(0px);
    transition: all 1.5s;
}

.tech-icons i {
    color: white;
    display: inline-block;
    height: 70%;
    width: 100%;
    font-size: 50px;
}

.tech-title {
    padding: 25px 0 0 0;
    width: 20%;
    margin: auto;
}

.hobbies {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: auto;
    color: white;
}

.imageSlider {
    width: 100%;
}

.imageSlider h3 {
    padding: 20px;
}

@media screen and (min-width: 1600px) {
    .icon h4 {
        font-size: 20px;
    }
}

@media screen and (min-width: 0px) and (max-width: 768px) {
    .about-hobbies, .about-title, .about-tech, .about-who, .desc {
        width: 80vw;
        margin-right: auto;
        margin-left: auto;
    }

    .about h1 {
        font-size: 25px;
    }

    .tech-icons i {
        font-size: 30px;
    }

    .tech-title {
        width: 100%;
    }

    .icon h4 {
        font-size: 8px;
        
    }

    .animate-before-tech-title {
        transform : translateX(-50%);
    }
}
