.projects {
    text-align: center;
}

.projects-title {
    position: relative;
    height: 5%;
    text-align: left;
    margin: 0px 20px 0px 20px;
    color: white;
}

.projects-main {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
    height: 95%;
    width: auto;
}

.projects-first, .projects-second {
    display: inline-flex;
    align-items: flex-start;
    justify-content: center; 
    height: 100%;
    width: 100%;
}

.projects-content {
    position: relative; 
    width: 100%;
    height: 90%;
    background-color: white;
    cursor: pointer; 
}

.projects-card {
    position: relative;
    margin: auto;  
    width: 55%;
    height: 70%;
    max-width: 475px;
    min-width: 400px;
    transition: all .5s;
}

.projects-card:hover {
    cursor: pointer;
    width: 63%;
    height: 73%;
    transition: all .5s;
}

.projects-header {
    position: relative;
    text-align: left;
    height: 10%;
}

.projects-header h2 {
    margin: 0px;
    color: rgb(155, 173, 155)
}

.projects-content span {
    position: absolute;
    left: 0;
    right: 0;
    top: 40%;
    margin-left: auto;
    margin-right: auto;
    opacity: 0;
    font-size: 30px;
    font-weight: 600;
}

.projects-content img {
    width: 100%;
    height: 100%;
}

.projects-content:hover {
    transition: all 1s;
    opacity: .7
}

.projects-content:hover span {
    transition: all 1s;
    color: rgb(0, 0, 0);
    opacity: 1
}

.first-row, .second-row {
    display: flex;
    align-items: center;
    width: 100%;
    height: 500px;
}

@media screen  and (max-width: 768px) {

    .projects h1 {
        font-size: 25px;
    }

    .projects-card {
        width: 90%;
        height: 60%;
        min-width: 100px;
        max-width: 170px;
    }

    .projects-card:hover {
        width: 93%;
        height: 63%;
    }

    .projects-header h2 {
        font-size: x-small;
    }

    .first-row, .second-row {
        height: 200px;
    }

    .projects-header {
        position: relative;
        text-align: left;
        height: 15%;
    }
}