body {
  margin: 0;
  padding: 0;
  width: 100%;
  min-height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  min-height: 100vh;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@media screen and (min-width: 0px) and (max-width: 768px) {
  body {
    margin: auto;
    padding: 0;
    max-width: 100vw;
    overflow-x: hidden;
  }
  
  .nav {
    position: relative;
    top: 0px
  }
  
  .body {
    max-width: 100vw;
    position: relative;
    display: inline-block;
  }
}
