.main {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: radial-gradient(circle at 24.1% 68.8%, rgb(50, 50, 50) 0%, rgb(0, 0, 0) 99.4%);
  margin: auto;
  padding: 0px;
  min-width: 1000px;
  width: 100%;
  overflow: hidden;
}

.home, .about, .projects {
  display: flex;
  flex-direction: column;
  padding: 5vh 0 5vh 0;
}

.nav {
  position: relative;
  top: 0px
}

.body {
  position: relative;
  display: inline-block
}

@font-face {
  font-family: 'Roboto';
  src: url('./Utils/fonts/RobotoMono-VariableFont_wght.ttf') format('truetype');
}

@media screen and (max-width: 768px) {
  .main {
    min-width: auto;
    max-width: 100vw;
  }

  .home, .about, .projects {
    display: flex;
    flex-direction: column;
    padding: 7% 0 7% 0;
    width: 95vw;
    margin-left: auto;
    margin-right: auto;
  }
  
  .nav {
    position: relative;
    top: 0px
  }
  
  .body {
    max-width: 100vw;
    position: relative;
    display: inline-block;
  }
}
