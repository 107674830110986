.nav {
    width: 100%;
    height: 50px;
}

nav {
    background-color: #222222;
    position: fixed;
    height: 50px;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    transition: 300ms;
    z-index: 10;
}

nav ul{
    margin: 0px;
    width: 400px;
    height: 100%;
    position: absolute;
    display: flex;
    right: 0px;
    justify-content: space-around;
}

nav li{
    text-align: center;
    display: inline-block;
    position: relative;
    margin: 10px;
}

nav button {
    border: none;
    cursor: pointer;
    background-color: transparent;
    color: white;
    font-size: larger;
}

nav button::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: white;
    transform-origin: bottom right;
    transition: transform 0.35s ease-out;
}

nav button:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

.home-title {
    text-align: center;
    color: white;
    width: 75%;
    margin: auto;
}

.home-title h1, .home-title h2 {
    font-family: 'Roboto';
    padding: 10px;
}

.icon-footer {
    margin-left: 10px;
    margin-right: 10px
}

a {
    color: white;
    text-decoration: none;
    font-size: 1.25em;
}

p {
    font-size: 18px;
    font-style: italic;
}

.animate-before {
    opacity: 0;
    transform: translateX(-10%);
}

.animate-after {
    opacity: 1;
    transform: translateX(0px);
    transition: all 1s;
}

.animate-after-sub {
    opacity: 1;
    transform: translateX(0px);
    transition: all 1s;
}

.footerMain {
    height: 200px;
    width: 100%;
    background-color: rgb(33, 32, 32);
}

.footerSocials {
    position: relative;
    display: flex;
    color: white;
    text-align: center;
    align-items: center;
    justify-content: center;
    height: 20%;
    width: 100%;
}

.title {
    font-size: 50px;
}


@media screen and (min-width: 0px) and (max-width: 768px) {
    .nav, nav {
        width: 100%;
        height: 40px
    }

    nav ul {
        width: 50%;
        right: 5%;
    }

    nav li {
        margin-left: 0;
        margin-right: 0;
    }

    nav button {
        font-size: 15px;
    }

    nav button:after {
        bottom: -3px;
    }

    p, h1, h2, h3 {
        font-size: 15px;
    }

    .home-title {
        width: 80%;
        margin-right: auto;
        margin-left: auto;
    }

    .footerMain {
        width: 100vw;
        height: 100px;
    }
}